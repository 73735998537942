import React from "react"
import { Link, graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import FeaturedNews from "../components/featuredNews"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  const archiveFeaturedImage = {
    fluid: getImage(data.wpMediaItem.localFile),
    alt: data.wpMediaItem.altText || "404",
  }

  return (
    <Layout location={location} title={siteTitle}>
      <div className="red-to-violet h-[200px] featured-image-wrapper">
        <div className="featured-image absolute -z-20 h-[500px] md:h-[700px] lg:h-[1024px] w-full">
          <GatsbyImage
            image={archiveFeaturedImage.fluid}
            alt={archiveFeaturedImage.alt}
            className="!absolute -z-20 !w-screen"
          />
          <div className="absolute -z-10 h-full w-full mix-blend-screen"></div>
          <div
            className="absolute h-[200px] sm:h-[150px] md:h-[200px] lg:h-[400px] bottom-0 w-full bg-contain"
            style={{
              backgroundImage:
                "url(https://wp.transtechsocial.org/wp-content/uploads/2023/01/white-fade-2.png)",
            }}
          ></div>
        </div>
      </div>
      <div className="red-to-violet four-oh-four">
        <div id="page-content" className="max-w-7xl mx-auto px-4 sm:px-8">
          <div className="w-full">
            <h1
              itemProp="headline"
              className="w-full font-hero text-7xl leading-none text-center text-black mb-12 lg:mb-24"
            >
              404
            </h1>
          </div>
          <div className="relative mb-32">
            <section
              id="blocks"
              itemProp="articleBody"
              className="entry-content w-full"
            >
              <h2 className="text-center">Whoa, how did you get here?</h2>
              <p className="text-center mx-auto">
                It's okay to be lost, we're here to help.
              </p>
              <div className="is-layout-flex wp-block-buttons is-content-justification-center">
                <div className="wp-block-button">
                  <Link
                    to="/"
                    className="wp-block-button__link has-black-color has-white-background-color has-background"
                  >
                    Go Back Home
                  </Link>
                </div>
              </div>
              <FeaturedNews />
              <div class="hidden h-4 w-4"></div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpMediaItem(databaseId: { eq: 21792 }) {
      title
      altText
      localFile {
        url
        childImageSharp {
          gatsbyImageData(
            webpOptions: { quality: 90 }
            width: 1920
            formats: WEBP
            layout: FIXED
            aspectRatio: 2
          )
        }
      }
    }
  }
`

export const Head = () => {
  return <Seo title="404: Not Found | TransTech Social Enterprises" />
}
